<ng-container *ngIf="src">
  <div class="img img-bg" *ngIf="isStoryBlok" [style.background-image]="'url(\''+src+'\')'">

  </div>
  @if (!isSvg && !isStoryBlok) {
    <img class="img" [src]="safeString || safeUrl" crossorigin="anonymous"
      [style.width]="width" [style.height]="height" [style.verticalAlign]="verticalAlign"
      [attr.loading]="lazy ? 'lazy' : undefined" [attr.draggable]="draggable">
  }
  @if (isSvg && safeString) {
    <mat-icon [svgIcon]="safeString"
      [style.width]="width" [style.height]="height" [style.verticalAlign]="verticalAlign">
    </mat-icon>
  }
</ng-container>
