<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2">
      <app-spirit-type-icon [type]="spirit.type"></app-spirit-type-icon>
      {{ spirit.name }}
    </h1>
  </div>
  <div class="sky-card-body">
    <div class="spirit-grid">
      <!-- Preview image -->
      <div class="spirit-grid-img spirit-grid-item sky-card-item">
        <div class="spirit-img"  *ngIf="spirit.imageUrl">
          <img [src]="spirit.imageUrl" [alt]="spirit.name" />
        </div>
      </div>
      <!-- 1: Wiki -->
      <app-wiki-link [wiki]="spirit._wiki" [aClass]="'spirit-grid-item sky-card-item sky-card-item-icon'"></app-wiki-link>
      <!-- 2: Season -->
      <a class="spirit-grid-item sky-card-item sky-card-item-icon" *ngIf="spirit.type === 'Season' || spirit.type === 'Guide'" [routerLink]="['/season', spirit.season?.guid]" [queryParams]="{ highlightTree: spirit.tree?.guid }">
        <img *ngIf="spirit.season?.iconUrl; else seasonIcon" [src]="spirit.season!.iconUrl" class="icon">
        <ng-template #seasonIcon><mat-icon>ac_unit</mat-icon></ng-template>
        <span class="menu-label">
          {{ spirit.season?.name }}
        </span>
      </a>
      <!-- 2: Event -->
      <div class="spirit-grid-item sky-card-item sky-card-item-icon" *ngIf="spirit.type === 'Event'">
        <mat-icon>event</mat-icon>
        <a *ngIf="event" class="menu-label" [routerLink]="['/event', event.guid]">
          {{ event.name }}
        </a>
      </div>
      <!-- 2: Regular -->
      <a class="spirit-grid-item sky-card-item sky-card-item-icon" *ngIf="spirit.type === 'Regular' || spirit.type === 'Elder' || spirit.type === 'Special'" [routerLink]="['/spirit']" [queryParams]="{type: spirit.type}">
        <mat-icon>person</mat-icon>
        <span class="menu-label" *ngIf="spirit.type === 'Regular'">
          Regular Spirit
        </span>
        <span class="menu-label" *ngIf="spirit.type === 'Elder' || spirit.type === 'Special'">
          {{ spirit.type }}
        </span>
      </a>
      <!-- Realm -->
      <a class="spirit-grid-item sky-card-item sky-card-item-icon" *ngIf="spirit.area?.realm" [routerLink]="['/realm', spirit.area!.realm!.guid]" [queryParams]="{ highlightTree: spirit.tree?.guid }">
        <mat-icon>map</mat-icon>
        <span class="menu-label">
          {{ spirit.area!.realm!.name }}
        </span>
      </a>
      <!-- Area -->
      <a class="spirit-grid-item sky-card-item sky-card-item-icon" *ngIf="spirit.area" [routerLink]="['/area', spirit.area!.guid]">
        <mat-icon>location_on</mat-icon>
        <span class="menu-label">
          {{ spirit.area!.name }}
        </span>
      </a>
    </div>
  </div>
</div>

<div class="sky-card mt spirit-trees">
  <div class="sky-card-header">
    <h2 class="h3 mb-0">Spirit tree</h2>
  </div>
  <div class="sky-card-body pad-0">
    <div class="sky-flex scroll-x pad">
      <div *ngFor="let item of trees" class="tree-wrapper p-inline-block">
        <app-spirit-tree
          [tree]="item.tree" [name]="item.name"
          [highlight]="highlightTree === item.tree.guid"
          [highlightItem]="highlightItem"
        ></app-spirit-tree>
      </div>
    </div>
  </div>
</div>
