<div class="content sky-border-solid" [class.highlight]="highlight" [attr.data-tree]="tree.guid">
  <div class="spirit-tree-scroll" [class.has-buttons]="enableControls" [class.pad-bottom]="hasCostAtRoot || padBottom">
    <!-- Action indication -->
    <div class="tree-action-background">
      @switch (nodeAction) {
        @case ('find') { <mat-icon>search</mat-icon> }
        @case ('favourite') { <mat-icon>star</mat-icon> }
      }
    </div>
    <!-- Controls -->
    @if (enableControls) {
      <div class="tree-actions">
        @if (showingNodeActions || nodeAction === 'unlock') {
          <div class="tree-action point" (click)="setNodeAction($event, 'unlock')">
            <div class="tree-action-icon find-item">
              <mat-icon>lock_open</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'unlock'">Unlock item</span>
          </div>
        }
        @if (showingNodeActions || nodeAction === 'find') {
          <div class="tree-action point" (click)="setNodeAction($event, 'find')">
            <div class="tree-action-icon find-item">
              <mat-icon>search</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'find'">Find item</span>
          </div>
        }
        @if (showingNodeActions || nodeAction === 'favourite') {
          <div class="tree-action point" (click)="setNodeAction($event, 'favourite')">
            <div class="tree-action-icon find-item">
              <mat-icon>star</mat-icon>
            </div>
            <span [class.active]="showingNodeActions && nodeAction === 'favourite'">Favourite item</span>
          </div>
        }
      </div>
      <div class="unlock-all point" (click)="unlockAll()" [ngbTooltip]="toggleUnlock ? 'Unlock all': 'Lock all'" container="body" placement="bottom-end">
        <mat-icon>enhanced_encryption</mat-icon>
      </div>
    }
    <div class="spirit-tree">
      <div class="column left">
        <div class="st-item" *ngFor="let node of left">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'left' }"></ng-container>
        </div>
      </div>
      <div class="column center">
        <div class="st-item" *ngFor="let node of center">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'center' }"></ng-container>
        </div>
      </div>
      <div class="column right">
        <div class="st-item" *ngFor="let node of right">
          <ng-container [ngTemplateOutlet]="tNode" [ngTemplateOutletContext]="{ $implicit: node, position: 'right' }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    @if (name || tree.name) {
      <div class="name">
        <ng-content select="div[name]"></ng-content>
        <div class="name-default">
          {{ name ?? tree.name }}
          @if (tsDate || rsDate) {
            <span class="c-accent ts-date">(<app-date [date]="tsDate || rsDate"></app-date>)</span>
          }
        </div>
      </div>
    }
    @if ((name || tree.name) && hasCost) {
      <div class="hr"></div>
    }
    @if (hasCost) {
      <div class="costs">
        <app-cost [cost]="totalCost" [remaining]="remainingCost"></app-cost>
      </div>
    }
    @if (tree.draft) {
      <div class="hr"></div>
      <div class="draft c-accent">
        <small>This spirit tree is not confirmed yet!</small>
      </div>
    }
  </div>
</div>

<ng-template #tNode let-node let-position="position">
  @if (node) {
    <app-node [node]="node" [action]="forceNodeAction ?? nodeAction" [position]="position" (nodeClicked)="onNodeClicked($event, node)"
      [highlight]="highlightItem ? highlightItemMap[node.item?.guid] : undefined"
      [opaque]="opaqueNodesAll || opaqueNodesMap[node.guid]"
      [glowType]="(forceNodeAction ?? nodeAction) !== 'unlock' ? 'attention' : 'default'">
    </app-node>
  }
  @if (nodeOverlayTemplate) { <ng-container [ngTemplateOutlet]="nodeOverlayTemplate" [ngTemplateOutletContext]="{ $implicit: node }"></ng-container> }
  <!-- Node arrows -->
  @if (node?.nw) { <div class="arrow arrow-left"></div> }
  @if (node?.n) { <div class="arrow arrow-up"></div> }
  @if (node?.ne) { <div class="arrow arrow-right"></div> }
</ng-template>
