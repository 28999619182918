<div class="costs" [ngbTooltip]="showTooltip ? costHover : undefined" container="body" placement="top" [class.cost-border-left]="borderLeft">
  @if (cost) {
    @if (cost.c || (showZeroes && cost.c === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.c, remaining: remaining?.c, icon: 'candle', iconClass: 'currency'}"></ng-container>
      </div>
    }
    @if (cost.sc || (showZeroes && cost.sc === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.sc, remaining: remaining?.sc, icon: 'candle', iconClass: 'seasonal'}"></ng-container>
      </div>
    }
    @if (cost.h || (showZeroes && cost.h === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.h, remaining: remaining?.h, icon: 'heart', iconClass: 'currency'}"></ng-container>
      </div>
    }
    @if (cost.sh || (showZeroes && cost.sh === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.sh, remaining: remaining?.sh, icon: 'heart', iconClass: 'seasonal'}"></ng-container>
      </div>
    }
    @if (cost.ac || (showZeroes && cost.ac === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.ac, remaining: remaining?.ac, icon: 'ascended-candle', iconClass: 'currency'}"></ng-container>
      </div>
    }
    @if (cost.ec || (showZeroes && cost.ec === 0)) {
      <div class="cost">
        <ng-container [ngTemplateOutlet]="costTemplate" [ngTemplateOutletContext]="{cost: cost.ec, remaining: remaining?.ec, icon: 'ticket', iconClass: 'currency'}"></ng-container>
      </div>
    }
  }
  @if (price || (showZeroes && price !== undefined)) {
    <div class="cost">
      <mat-icon class="cost-icon cost-icon-mat icon-middle c-new">attach_money</mat-icon><div><span>{{ price | number:'1.2-2' }}</span></div>
    </div>
  }
</div>

<ng-template #costTemplate let-cost="cost" let-remaining="remaining" let-icon="icon" let-iconClass="iconClass">
  <mat-icon [class]="iconClass + ' cost-icon icon-middle'" [svgIcon]="icon" [inline]="false"></mat-icon>
  <div>
    @if (cost) {
      @if (remaining) {
        <span> {{ remaining || 0 }}</span>
        <span class="c-accent"> ({{ cost || 0 }})</span>
      } @else if (remaining === 0) {
        <b class="c-completed"> {{ cost || 0 }}</b>
      } @else {
        <span> {{ cost || 0 }}</span>
      }
    } @else if (showZeroes) {
      <span>0</span>
    }
  </div>
</ng-template>

<ng-template #costHover>
  <div style="text-align: left;">
    <span>■ Cost of items not yet unlocked.</span><br/>
    @if (remaining) {
      <span class="c-accent">■ Total cost of all items.</span><br/>
    }
    <span class="c-completed">■ All items unlocked.</span>
  </div>
</ng-template>
