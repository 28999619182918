<div [attr.data-position]="position" [class.highlight]="highlight">
  <div *ngIf="node.item" class="item-container" [ngbTooltip]="nodeHover" [placement]="tooltipPlacement" container="body"
    (click)="nodeClick($event)" (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
  >
    <app-item-icon [item]="node.item" [node]="node" [opaque]="hover || opaque" [glowType]="glowType" [subIcons]="['favourite', 'unlock', 'seasonPass']"></app-item-icon>
  </div>
  <div class="cost" *ngIf="node?.c">
    {{ node.c }}<mat-icon class="icon-middle" svgIcon="candle" [inline]="false"></mat-icon>
  </div>
  <div class="cost" *ngIf="node?.sc">
    {{ node.sc }}<mat-icon class="icon-middle" svgIcon="season-candle" [inline]="false"></mat-icon>
  </div>
  <div class="cost" *ngIf="node?.h">
    {{ node.h }}<mat-icon class="icon-middle" svgIcon="heart" [inline]="false"></mat-icon>
  </div>
  <div class="cost" *ngIf="node?.sh">
    {{ node.sh }}<mat-icon class="seasonal icon-middle" svgIcon="season-heart" [inline]="false"></mat-icon>
  </div>
  <div class="cost ascend" *ngIf="node?.ac">
    {{ node.ac }}<mat-icon class="icon-middle" svgIcon="ascended-candle" [inline]="false"></mat-icon>
  </div>
  <div class="cost" *ngIf="node?.ec">
    {{ node.ec }}<mat-icon class="icon-middle" svgIcon="ticket" [inline]="false"></mat-icon>
  </div>
</div>

<ng-template #nodeHover>
  <ng-container *ngIf="node.item">
    <span class="ws-nw">{{node.item.name}}</span>
  </ng-container>
</ng-template>
